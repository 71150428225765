import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CdkMenuModule } from '@angular/cdk/menu';

import { LanguagePickerComponent } from './components';

@NgModule({
  declarations: [LanguagePickerComponent],
  imports: [CommonModule, TranslateModule, CdkMenuModule],
  exports: [LanguagePickerComponent, TranslateModule],
  providers: [],
})
export class L10nModule {}
