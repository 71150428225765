<button type="button" id="lang-button" [cdkMenuTriggerFor]="menu">
  <img class="lang-icon" src="/images/locales/{{ localisationService.language() }}.svg"
    [attr.alt]="localisationService.language()" />
</button>

<ng-template #menu>
  <div cdkMenu>
    @for (locale of localisationService.locales; track $index) {
    <button class="lang-item" (click)="localisationService.use(locale); collapse()" cdkMenuItem>
      {{ "locales." + locale | translate }}
    </button>
    }
  </div>
</ng-template>