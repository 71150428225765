import { Component, inject, signal } from '@angular/core';
import { LocalisationService } from '../services';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrl: './language-picker.component.scss',
})
export class LanguagePickerComponent {
  protected localisationService = inject(LocalisationService);
  protected expanded = signal<boolean>(false);

  toggle(): void {
    this.expanded.update((value) => !value);
  }

  collapse(): void {
    this.expanded.set(false);
  }
}
