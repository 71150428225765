import { HttpClient } from '@angular/common/http';
import { importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LOCALES } from './locales';

function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'l10n/', '.json');
}

export const provideTranslations = (locales: string[]) => {
  return makeEnvironmentProviders([
    {
      provide: LOCALES,
      useValue: locales,
    },
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: localStorage.getItem('language') || 'en',
        useDefaultLang: true,
        loader: {
          provide: TranslateLoader,
          useFactory: translateHttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
  ]);
};
